<template>
    <admin-dashboard-layout>
        <v-data-table
            :search="search"
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="users"
            :items-per-page="quantityItems"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:top>
                <v-text-field
                    v-model="search"
                    label="Search"
                    class="mx-4"
                ></v-text-field>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at ? moment(item.created_at).format('LLL') : '' }}
            </template>
            <template v-slot:item.blocked_at="{ item }">
                {{ item.blocked_at ? moment(item.blocked_at).format('LLL') : '' }}
            </template>
            <template v-slot:item.is_online="{ item }">
                <v-badge color="ml-3 success" :title="'Last online ' + moment(item.last_online_at).format('LLL')"
                         v-if="item.is_online"></v-badge>
                <v-badge color="ml-3 error" :title="'Last online ' + moment(item.last_online_at).format('LLL')"
                         v-else></v-badge>
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import User from '../../../models/admin/User';
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import {ADMIN_QUANTITY_ITEMS_LIST} from "../../../configs/constants";
import {mapMutations} from "vuex";
/* eslint-disable */
export default {
    name: "users-index",
    components: {AdminDashboardLayout},
    data: function () {
        return {
            loading: false,
            quantityItems: ADMIN_QUANTITY_ITEMS_LIST,
            users: [],
            search: '',
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Name', value: 'name'},
                {text: 'Email', value: 'email'},
                {text: 'Created at', value: 'created_at'},
                {text: 'Blocked at', value: 'blocked_at'},
                {text: 'Status', value: 'is_online'},
            ],
        }
    },
    methods: {
        handleClickRow(user) {
            this.$router.push({name: 'admin.users.show', params: {id: user.id}})
        },
        handlePusherListener() {
            this.$echo.connector.connect()
            this.$echo.private('user-registered')
                .listen('UserSignUpEvent', async (data) => {
                    if (typeof data.message !== 'undefined') {
                        this.showSnackBar(data.message)
                        this.users = await User.get()
                    }
                })
                .error((err) => {
                    console.error(err)
                })
        },
        ...mapMutations(['showSnackBar'])
    },
    computed: {
        refreshTime() {
            const minute = 60000
            if (process.env.VUE_APP_LAST_ONLINE_REFRESH_MINUTES) {
                return process.env.VUE_APP_LAST_ONLINE_REFRESH_MINUTES * minute
            }
            return 10 * minute
        }
    },
    async mounted() {
        this.loading = true
        this.users = await User.get()
        this.loading = false
        setInterval(async () => {
            this.users = await User.get()
        }, this.refreshTime)
        this.handlePusherListener()
    },
}
</script>

<style scoped>

</style>
